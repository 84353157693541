import Big from "big.js";
import { Contract, providers } from "ethers";
import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import {
    Button,
    styleReset,
    Table,
    TableBody,
    TableDataCell,
    TableHead,
    TableHeadCell,
    TableRow,
    Window,
    WindowContent,
    WindowHeader,
} from "react95";
import ms_sans_serif from "react95/dist/fonts/ms_sans_serif.woff2";
import ms_sans_serif_bold from "react95/dist/fonts/ms_sans_serif_bold.woff2";
import original from "react95/dist/themes/original";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import "datejs";
import unipoolABI from "./unipool-events.json";

const formatN = (n) => Number(new Big(n.toString()).div(10 ** 18).toFixed(8));

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
  body {
    font-family: 'ms_sans_serif';
    background: teal;
  }
  ${styleReset}
  .close-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: -1px;
    margin-top: -1px;
    transform: rotateZ(45deg);
    position: relative;
    &:before,
    &:after {
      content: '';
      position: absolute;
      background: black;
    }
    &:before {
      height: 100%;
      width: 3px;
      left: 50%;
      transform: translateX(-50%);
    }
    &:after {
      height: 3px;
      width: 100%;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .window-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 0.15em 0.1em 0em 0.6em !important;
  }
  .window {
    margin: 2rem;
    width: 25em;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    display: block;
    height: 16em;
  }
  .window-content { padding: 0; margin: 0.25em; }
  html, body, #root, .wrapper {
    font-family: 'ms_sans_serif';
    margin: 0;
    padding: 0;
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  thead, tbody, tr, td, th { display: block !important; }
  tbody {
    overflow-y: auto;
  }
  thead tr {
    &:hover { background-color: inherit !important; }
  }
  tbody {
    height: 10.4em;
    tr {
      .green &:hover {
        background-color: #afa !important;
      }
      .red &:hover {
        background-color: #faa !important;
      }
      &:hover {
        color: #000 !important;
      }
    }
  }

  tbody td { text-align: right; }
  tbody td, thead th {
    width: 4.93em;
    height: 2em;
    float: left;
    &.time {
      text-align: center;
    }
  }
  tr:after {
    content: ' ';
    display: block;
    visibility: hidden;
    clear: both;
  }
`;

// const provider = new providers.Web3Provider(window['ethereum']);
const provider = new providers.WebSocketProvider('wss://eth-mainnet.ws.alchemyapi.io/v2/SdRF6jWT5h13wIZuFA-aI9IUXO9FKhkd')
const TradesWindow = props => {
    props = { ...props };
    const ref = React.createRef();
    if (!('token1' in props)) props.token1 = 'WETH';
    const [ trades, setTrades ] = useState([]);
    const [ isActive, setIsActive ] = useState(false);

    useEffect(() => {
        const setupFeed = async () => {
            const pool = new Contract(props.addr, unipoolABI, provider);
            pool.on('Swap', (sender, TOKENIn, ETHIn, TOKENOut, ETHOut, to) => {
                const act = ETHIn.gt(0) ? 'BUY' : 'SELL';
                const amt1 = ETHIn.gt(0) ? TOKENOut : TOKENIn;
                const amt2 = ETHIn.gt(0) ? ETHIn : ETHOut;
                const trade = { sender, to, act, amt1, amt2, ts: new Date() };
                console.log(`${act} ${formatN(amt1)} ${props.token0} for ${formatN(amt2)} ${props.token1}`);
                setTrades(t => [ trade, ...t ]);
            });
        };
        setupFeed();
        return () => props.provider.removeAllListeners();
    }, [ props.addr, props.provider, props.token0, props.token1 ]);

    useEffect(() => {
        const root = document.getRootNode();
        const checkActive = e => {
            const node = ref.current?.findDOMNode();
            const active = node && node.contains(e.target);
            setIsActive(active);
            if (!node || !node.style) return;
            node.style.zIndex = active ? 9999 : 1;
        };
        root.addEventListener('mousedown', checkActive, true);
        return function cleanup() {
            root.removeEventListener('mousedown', checkActive, true);
        };
    }, [ ref ]);

    return (
        <Draggable
            ref={ref}
            scale={1}
            position={null}
            handle=".handle"
            defaultPosition={{ x: 0, y: 0 }}
        >
            <Window className='window'>
                <WindowHeader active={isActive} className='window-header handle'>
                    <span>{props.token0}/{props.token1} Trades</span>
                    <Button>
                        <span className='close-icon'/>
                    </Button>
                </WindowHeader>
                <WindowContent className='window-content'>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeadCell>Type</TableHeadCell>
                                <TableHeadCell>{props.token0}</TableHeadCell>
                                <TableHeadCell>{props.token1}</TableHeadCell>
                                <TableHeadCell className="time">Time</TableHeadCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                trades.map(trade => (
                                    <TableRow key={trade.ts.getTime()} style={{
                                        backgroundColor: trade.act === 'BUY' ? '#e0ffe0' : '#ffe0e0'
                                    }}>
                                        <TableDataCell style={{ textAlign: 'center' }}>
                                            <span role='img' aria-label='LEAF'>
                                                {trade.act} {trade.act === 'BUY' ? '🚀' : '🤷‍♂️'}
                                            </span>
                                        </TableDataCell>
                                        <TableDataCell>{formatN(trade.amt1)}</TableDataCell>
                                        <TableDataCell>{formatN(trade.amt2)}</TableDataCell>
                                        <TableDataCell className="time">
                                            {trade.ts.toString('HH:mm:ss')}
                                        </TableDataCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </WindowContent>
            </Window>
        </Draggable>
    );
};
const App = () => {
    return (
        <div className="wrapper">
            <GlobalStyles/>
            <ThemeProvider theme={original}>
                <TradesWindow provider={provider} token0="PICKLE" addr="0xdc98556Ce24f007A5eF6dC1CE96322d65832A819"/>
                <TradesWindow provider={provider} token0="CORE" addr="0x32ce7e48debdccbfe0cd037cc89526e4382cb81b"/>
            </ThemeProvider>
        </div>
    );
};

export default App;
